export enum ApiPaths {
  API_V1_FAQ = '/api/v1/faq',
  API_V1_FAQ_CATEGORIES = '/api/v1/faq/categories',
  API_V1_PARKINGS = '/api/v1/parkings',
  API_V1_CITIES = '/api/v1/cities',
  API_V1_SUBSCRIPTIONS = '/api/v1/subscriptions',
  API_V1_CUSTOMIZED_CLIENTS = '/api/v1/customized-clients',
  API_V1_CATEGORIES = 'api/v1/categories',
  API_V1_BLOCKINGS = '/api/v1/blockings'
}
